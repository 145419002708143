.call-to-action-button_wrapper {
  display: flex;
  width: 270px;
}

.call-to-action-button_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 47px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #00AABC;
  color: white;
  position: relative;
  margin: 0;

  font-family: "AvenirNext";
  font-size: 14px;
  font-weight: 900;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 16px;
  font-stretch: normal;

  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #CCCCCC;
    color: #A7A7A7;
  }

  & > div {
    display: flex;
    align-items: center;
  }
}