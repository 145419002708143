@import 'src/assets/styles/global';

.login_footer_wrapper {
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include mobile {
    height: 100%;
    max-height: 180px;
  }

  .login_footer_logo-wrapper {
    display: contents;
    @include mobile {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-items: center;
      justify-content: center;
    }
  }
}
