.button {
  width: 170px;
  height: 50px;
  border: solid 1px #ccd2da;
  background-color: #658D1B;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  color: #fff;
  font-family: AvenirNext, Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  text-align: center;
  justify-content: center;

  &:hover,
  &.button_checked {
    background-color: #658D1B;
    border: none;
    & span {
      color: white;
    }
  }

  &.button_checked {
    font-weight: 900;
    & span {
      display: flex;
      justify-content: center;
      align-items: center;
      & svg {
        color: white;
      }
      &::before {
        content: '';
        color: white;
        transform: scale(.1);
        display: inline-block;
        width: 7px;
        height: 14px;
        border-bottom: 2px solid white;
        border-right: 2px solid white;
        transform: rotate(45deg);
        margin-bottom: 7px;
        margin-right: 10px;
      }
    }
  }

  //@include mobile {
  //  width: 100%;
  //}
}
