$header-color: #4a90e2;

.main-container {
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  text-align: center;
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: $header-color;
    font-size: 18px;
  }

  label.MuiFormControlLabel-root {
    margin-right: 0;
  }

  h1 {
    font-size: 18px;
    color: $header-color;
    font-weight: normal;
    height: 38px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }
}

// Application

.application {
  width: 140px;
  text-align: center;
  margin-top: 1em;
}

// Actions style

.actions {
  cursor: pointer;
}

// Connection Date

.connection-date {
  width: 50px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}

// Last Action

.last-action {
  font-size: 14px;
  font-weight: 700;
}

// Accounts & Solutions

.accounts-solutions {
  margin-top: 1em;
  display: flex;
  flex-direction: column;

  label.MuiFormControlLabel-root {
    text-align: left;
    width: 160px;
    margin: 0 auto;
    margin-bottom: 0.5em;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: black;
    font-size: 14px;
    text-align: left;
  }
  .MuiListItem-gutters {
    padding-left: 0;
  }
  .MuiListItemText-primary {
    font-weight: 700;
  }
}

// Consent & Connect

.consent-connect {
  margin-top: 1em;
  width: 114px;
  text-align: center;
}

.consent-modal {
  height: 100%;
  display: flex;
  flex-direction: column;

  .sub-header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2em;
  }

  h1 {
    font-size: 18px;
    color: $header-color;
    font-weight: normal;
    height: 38px;
  }

  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  img {
    width: 210px;
  }

  .body {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .consent-time {
      display: flex;
      flex-direction: column;

      div {
        display: flex;
        flex-direction: row;
      }
    }

    img {
      width: 65px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: auto;
  }
}

.save-button {
  width: 100%;
  text-align: center;
}

.fi-container {
  margin: 1em 100px 0 70px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
