.multi-checkbox-choice-dropdown_container {
  .MuiOutlinedInput-root {
    height: 42px;
  }
  .MuiOutlinedInput-root.MuiInputBase-root {
    font-family: AvenirNext, serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }
  .MuiFormControl-root {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.multi-checkbox-choice-dropdown_selectAllCheckboxSeparator {
  border-top: 1px solid #5c6263;
}

.multi-checkbox-choice-dropdown_checkboxContainer {
  display: flex;
  padding-bottom: 20px;
  margin: 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #b1c3c6;
  /*@include mobile {
    flex-direction: column;
  }*/
}

.multi-checkbox-choice-dropdown_checkboxRow {
  width: 50%;
  /*@include mobile {
    width: 100%;
  }*/
}