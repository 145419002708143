@import 'src/assets/styles/global';

.applications_container {
  .header_wrapper {
    margin-top: 80px;
    margin-bottom: 32px;
    width: 100%;
  }
  .header_titleFirst {
    .fi-logo {
      width: auto;
      height: 72px;
      margin: 0 auto;
    }
    margin-bottom: 70px;
  }
  .header_titleSecond span {
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #041b2b;
    margin-bottom: 40px;
  }
  .header_titleThird {
    width: 100%;
    span {
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #041b2b;
    }
  }

  .page-layout {
    width: 560px;
  }
}


.applications_list {
  &.panel {
    padding: 0;
    margin-bottom: 104px;
  }

  width: 100%;
  align-items: center;

  .applications_list-item {
    width: 100%;
    position: relative;
    margin-bottom: 32px;

    .applications_list-item_status {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #041b2b;
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  .button {
    padding: 23px;
    background-color: #fff;
    height: 136px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;

    &:hover {
      background-color: #F5F5F5;
      outline: solid 4px #00AABB;
    }

    span {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    img {
      width: 197px;
      height: 88px;
      object-fit: contain;
    }
  }
}

.applications_button-panel {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 48px;

  .button {
    background-color: #658D1B;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
  }
}

@include mobile {
  .applications_container {
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .page-layout {
      width: 100%;
    }

    .header_wrapper {
      margin-top: 62px;
      margin-bottom: 50px;
    }
    .header_titleFirst {
      margin-bottom: 65px;
      padding-left: 20px;
      padding-right: 20px;
      .fi-logo {
        max-height: 56px;
        max-width: 100%;
        height: inherit;
        width: unset;
        margin: 0 auto;
      }
    }
    .header_titleSecond span {
      font-size: 28px;
      font-weight: bold;
    }
    .header_titleThird span {
      font-size: 14px;
      font-weight: normal;
    }
    .applications_list .button {
      padding: 16px;
      height: 96px;
      img {
        width: 100px;
        height: 33px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .applications_list.panel {
      margin-bottom: 65px;
    }
  }
}