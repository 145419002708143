@import 'src/assets/styles/global';

.app-header-container {
  margin: 0 0 0 0;
  width: 100%;
  background-image: url('../../assets/images/top-banner.svg');
  height: 48px;
  flex-shrink: 0;
  @include mobile {
    display: none;
  }
}