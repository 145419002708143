@import 'src/assets/styles/global';

.terms_container {
  //height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .page-layout {
    max-width: 842px;
    width: 100%;
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #041b2b;
  }

  .header_wrapper {
    margin-top: 88px;
    margin-bottom: 24px;
    @include mobile {
      margin-top: 62px;
    }
  }
  .header_titleFirst {
    .fi-logo {
      /*width: auto;
      height: 71px;*/
      max-height: 56px;
      max-width: 100%;
      height: inherit;
      width: unset;
      margin: 0 auto;
    }
    margin-bottom: 70px;
    line-height: 0;
  }
  .header_titleSecond span {
    font-family: AvenirNext;
    font-size: 38px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #041b2b;
  }

  .terms {
    display: flex;
    flex-direction: column;
    font-family: AvenirNext;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #041b2b;

    .terms_intro {
      margin-bottom: 24px;
    }

    @include desktop-and-above {
      //width: 600px;
      //height: 100%;
    }

    .MuiTypography-root {
      width: 100%;
      font-family: AvenirNext;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #041b2b;
    }

    iframe {
      width: 100%;
      height: 40vh;
      border: none;
    }

    .error-wrapper_main {
      white-space: unset;
      height: unset;
      text-align: center;
    }
  }

  .terms_accept-checkbox-wrapper {
    margin-top: 32px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .terms_outro {
    margin-bottom: 40px;
  }
  .call-to-action-button_wrapper {
    margin-bottom: 48px;
  }
}

@include mobile {
  .terms_container {
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    .page-layout {
    }

    .header_wrapper {
      margin-top: 62px;
      margin-bottom: 35px;
    }
    .header_titleFirst {
      margin-bottom: 65px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .header_titleSecond span {
      font-size: 28px;
      font-weight: bold;
    }
    .terms_intro {
      font-size: 14px;
      font-weight: normal;
    }
    .terms_outro {
      font-size: 14px;
      font-weight: normal;
    }
  }
}