@font-face {
  font-family: 'AvenirNext-Roman';
  src: url('./assets/fonts/avenir/Avenir-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-BookOblique.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-HeavyOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-MediumOblique.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src:url('./assets/fonts/avenir/Avenir-LightOblique.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-Black';
  src: url('./assets/fonts/avenir/Avenir-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext-Black';
  src: url('./assets/fonts/avenir/Avenir-Black.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext-Black';
  src: url('./assets/fonts/avenir/Avenir-BlackOblique.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('./assets/fonts/avenir/Avenir-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}