@import 'src/assets/styles/global';

.accounts_container {
  .header_wrapper {
    margin-top: 80px;
    margin-bottom: 64px;
  }
  .header_titleFirst {
    .fi-logo {
      width: auto;
      height: 72px;
      margin: 0 auto;
    }
    margin-bottom: 70px;
  }

  .page-layout {
    width: 560px;
  }

  .panel {
    padding: 0;
    margin-bottom: 0;
    width: 100%;
  }

  .accounts_app-logo-container {
    img {
      max-width: 100%;
      max-height: 100%;
    }
    height: 105px;
    vertical-align: middle;
    display: table-cell;
    line-height: 0;
    text-align: center;
  }
}

.accounts_list {
  align-items: center;

  .multi-checkbox-choice_container {
    border: 0;
    margin: 0;
    padding: 0;
    margin-top: 11px;
    width: 100%;
  }

  .multi-checkbox-choice_checkboxListContainer {
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .multi-checkbox-choice_checkboxRow {
    width: 100%;
  }

  .multi-checkbox-choice_checkboxContainer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .multi-checkbox-choice-dropdown_container {
    width: 182px;
    flex-shrink: 0;
  }
}

.accounts_payment-options {
  .MuiPaper-root.MuiMenu-paper {
    width: 182px;
  }
  .labeled-checkbox_wrapper {
    white-space: break-spaces;
  }
}

.accounts_button-panel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-bottom: 48px;
  padding-top: 120px;
}

@include mobile {
  .accounts_container {
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .page-layout {
      width: 100%;
    }

    .header_wrapper {
      margin-top: 62px;
      margin-bottom: 32px;
    }
    .header_titleFirst {
      margin-bottom: 65px;
      padding-left: 20px;
      padding-right: 20px;
      .fi-logo {
        max-height: 56px;
        max-width: 100%;
        height: inherit;
        width: unset;
        margin: 0 auto;
      }
    }
    .header_titleSecond span {
      font-size: 14px;
      font-weight: normal;
    }
    .header_titleThird span {
      font-size: 14px;
      font-weight: normal;
    }
    .accounts_app-logo-container {
      height: 60px;
    }
    .accounts_list .button {
      padding: 16px;
      height: 96px;
      img {
        width: 100px;
        height: 33px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }
    .accounts_list.panel {
      overflow-y: scroll;
      margin-bottom: 65px;
      .multi-checkbox-choice_selectAllCheckboxContainer, .multi-checkbox-choice_checkboxContainer {
        height: 57px;
      }
    }
    .accounts_button-panel {
      padding-top: 35px;
      padding-bottom: 35px;
      flex-direction: column;
      .cancel-button_wrapper {
        margin-bottom: 8px;
      }
    }
  }
}