.error-wrapper_contentWrapper {
  display: flex;
  flex-shrink: 0;
  flex-grow: 1;
  flex-direction: column;
}

.error-wrapper_main {
  display: flex;
  white-space: nowrap;
  align-items: center;
  height: 20px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff7282;
}

