.labeled-checkbox_wrapper {
  display: flex;
  align-items: center;
  & input {
    background-color: black;
  }

  .labeled-checkbox_unchecked-icon {
    width: 18px;
    height: 18px;
    object-fit: contain;
    box-sizing: border-box;
    border-radius: 2px;
    border: solid 1px #041b2b;
  }

  .labeled-checkbox_checked-icon {
    width: 18px;
    height: 18px;
    object-fit: contain;
    background-image: url('../../assets/images/icon_awesome-check-square.svg');
  }

  .Mui-disabled {
    .labeled-checkbox_unchecked-icon {
      border: solid 1px #ddd;
      background-color: #fafafa;
    }
  }

  // margin around checkbox
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    margin: 0;
  }

  // padding around checkbox
  .MuiCheckbox-root.MuiButtonBase-root.MuiCheckbox-root.PrivateSwitchBase-root {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 10px;
    padding-right: 24px;
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #658D1B !important;
  }

  .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #041b2b;
  }

  .Mui-checked {
    & + span {
      //font-weight: 900 !important;
    }
  }

  .MuiIconButton-root {
    &:hover{
      background-color: transparent !important;
    }
  }
}