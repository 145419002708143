@import 'src/assets/styles/global';

.login_container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background-image: url('../../assets/images/login-background.svg');
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  .login_logo {
    position: absolute;
    top: 63px;
    left: 140px;
  }

  .header_wrapper {
    margin-top: 0;
    margin-bottom: 35px;
  }

  .header_titleFirst {
    .fi-logo {
      width: auto;
      height: 72px;
      margin: 0 auto;
    }
    span {
      line-height: 0;
    }
    margin-bottom: 80px;
  }
  .header_titleSecond span {
    font-family: AvenirNext;
    font-size: 34px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #041b2b;
  }
  .page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    //overflow-y: auto;
  }
  .login_password {
    margin-top: 37px
  }
  .login_error-message {
    width: 100%;
    margin-top: 28px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    color: #f00;
  }
  .call-to-action-button_wrapper {
    margin-top: auto;
    margin-bottom: 32px;
  }
  .fi-help-instructions {
    //padding-top: 28px;
    padding-bottom: 46px;
  }
}

.form-container.MuiPaper-root {
  border-radius: 10px;
}

.form-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: auto;
  z-index: 1;
  //@include desktop-and-above {
    width: 611px;
    height: 780px;
  //}
  padding-top: 40px;
  padding-left: 114px;
  padding-right: 114px;
  padding-bottom: 25px;
  background-color: white;
  .form-header {
    font-size: 14px;
    font-weight: bolder;
    text-align: center;
    margin-top: 19px;
  }
  .form-link {
    &:visited, &:link, &:active
    {
      text-decoration: none;
    }
    &:hover {
      text-decoration: underline;
    }
    width: 100%;
    text-align: right;
    margin-top: 8px;
    cursor: pointer;
    font-family: AvenirNext;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: 16px;
    color: #37489b;
  }
}

@include mobile {
  .login_container {
    position: absolute;
    top: 0;
    .header_titleFirst {
      .fi-logo {
        width: 100%;
        height: unset;
      }
      margin-top: 52px;
      margin-bottom: 65px;
      padding-left: 20px;
      padding-right: 20px;
    }
    .header_titleSecond span {
      font-size: 28px;
      font-weight: bold;
    }
    .page-content {
    }
    .login_password {
      margin-top: 37px
    }
    .login_error-message {
    }
    .call-to-action-button_wrapper {
    }
    .fi-help-instructions {
      padding-bottom: 0;
      padding-top: 10px;
    }
  }

  .form-container.MuiPaper-root {
    border-radius: 0;
  }

  .form-container {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 10px;
  }
}