.multi-checkbox-choice_container {
  margin-top: 20px;
}

.multi-checkbox-choice_selectAllCheckboxContainer {
  display: flex;
  height: 78px;
  .labeled-checkbox_wrapper {
    .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
      font-weight: bold;
    }
  }
}

.multi-checkbox-choice_selectAllCheckboxSeparator {
  border-top: 1px solid #b3b3b3;
}

.multi-checkbox-choice_checkboxContainer {
  border: 0;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #b3b3b3;
}

.multi-checkbox-choice_checkboxListContainer {
  display: flex;
  padding-bottom: 20px;
  margin: 0;
  margin-bottom: 20px;
  //border-bottom: 1px solid #b3b3b3;
  /*@include mobile {
    flex-direction: column;
  }*/
}

.multi-checkbox-choice_checkboxContainer {
  display: flex;
  height: 78px;
}

.multi-checkbox-choice_checkboxRow {
  width: 50%;
  /*@include mobile {
    width: 100%;
  }*/
}