@import 'src/assets/styles/global';

.application-instructions_container {
  .header_wrapper {
    margin-top: 80px;
    margin-bottom: 64px;
  }
  .header_titleFirst {
    .fi-logo {
      width: auto;
      height: 72px;
      margin: 0 auto;
    }
  }

  .page-layout {
    width: 560px;
  }

  .application-instructions_content_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .application-instructions_app-logo-container {
    img {
      max-width: 100%;
      max-height: 100%;
    }
    height: 128px;
    vertical-align: middle;
    display: table-cell;
    line-height: 0;
    text-align: center;
  }
}

.application-instructions_instructions {
  margin-top: 84px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #041b2b;
}

.application-instructions_button-panel {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  bottom: 0px;
  position: fixed;
  margin-top: 48px;
  margin-bottom: 48px;
}

@include mobile {
  .application-instructions_container {
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .page-layout {
      width: 100%;
    }

    .header_wrapper {
      margin-top: 62px;
      margin-bottom: 32px;
    }
    .header_titleFirst {
      padding-left: 20px;
      padding-right: 20px;
      .fi-logo {
        max-height: 56px;
        max-width: 100%;
        height: inherit;
        width: unset;
        margin: 0 auto;
      }
    }
    .application-instructions_content_wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .application-instructions_app-logo-container {
      height: 60px;
    }
    .application-instructions_instructions {
      font-size: 14px;
      font-weight: normal;
      margin-top: 44px;
    }
    .application-instructions_button-panel {
      margin-bottom: 36px;
      margin-top: 36px;
    }
  }
}