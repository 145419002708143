@import 'src/assets/styles/global';
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

html,
body {
  overflow-x: hidden;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: AvenirNext, Roboto, sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

div#root {
  height: 100%;
}

.main-app-container {
  flex-direction: column;
  flex-grow: 1;
  margin: 0;//20px 20px 20px 20px;
  @include desktop-and-above {
    height: 100%;
    margin: 0 auto;
  }
  &-no-scroller {
    overflow: hidden;
  }
  //@include desktop-and-above {
    display: flex;
    align-items: center;
    //justify-content: center;
  //}
  @include mobile {
    height: 100%;
  }
}

@import "src/scss/components/_main.scss";
