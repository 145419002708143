@import 'src/assets/styles/global';

.generic-message-page-template_container {
  .header_wrapper {
    margin-bottom: 88px;
  }
  .header_titleFirst {
    margin-bottom: 0;
  }

  .generic-message-page-template_message {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #041b2b;
    flex-grow: 1;
  }
}
