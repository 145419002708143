.labeled-input {
  font-size: 14px;
  width: 100%;
}

.labeled-input_label {
  opacity: 1;
  font-family: AvenirNext;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #041b2b;
  margin-top: 0px;
  margin-bottom: 7px;
}

.labeled-input_input {
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 1px #707070;
  opacity: 1;
  height: 45px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

.labeled-input_input_error {
  border-color: rgb(204, 7, 7);
}