.header_wrapper {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  /*@include mobile {
    margin-top: 20px;
    margin-bottom: 20px;
  }*/
}

.header_titleFirst {
  display: flex;
  justify-content: center;
  & > span {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #041b2b;
  }
  & > span > p {
    margin: 0px;
  }
  /*@include mobile {
    & > span {
      font-size: 20px;
      font-weight: 900;
      padding-left: 20px;
      padding-right: 20px;
    }
  }*/
}

.header_titleSecond {
  display: flex;
  justify-content: center;
  & > span {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #041b2b;
  }
  /*@include mobile {
    & > span {
      font-size: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }*/
}
